<template>
  <div class="fullScreen" :class="{ show: true }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 全局规则配置</el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain">
      <div>
        <span></span>
      </div>
      <el-tabs
        v-model="activeName"
        class="fullScreenMainCon"
        @tab-click="handleClick"
      >
        <!-- 规则 -->
        <el-tab-pane
          v-if="permission(['PERFORMANCE_RULES_VIEW'])"
          label="可支配费用规则"
          name="可支配费用规则"
        >
          <ExpenseRules
            :source="source"
            :rulesType="rulesType"
            :ExpenseRulesModule="ExpenseRulesModule"
            :ExpenseRulesList="ExpenseRulesList"
            :usedExpenseRulesList="usedExpenseRulesList"
            ref="expenserules"
          ></ExpenseRules>
        </el-tab-pane>
        <el-tab-pane
          v-if="permission(['PERFORMANCE_RULES_VIEW'])"
          label="商务绩效规则"
          name="商务绩效规则"
        >
          <BusinessPerformance
            :source="source"
            :rulesType="rulesType"
            :BusinessPerformanceModule="BusinessPerformanceModule"
            :BusinessPerformanceList="BusinessPerformanceList"
            :usedBusinessPerformanceList="usedBusinessPerformanceList"
            ref="businessperformance"
          ></BusinessPerformance>
        </el-tab-pane>
        <el-tab-pane
          v-if="permission(['PERFORMANCE_RULES_VIEW'])"
          label="部门绩效规则"
          name="部门绩效规则"
        >
          <DepartmentPerformance
            :source="source"
            :rulesType="rulesType"
            :DepartmentPerformanceModule="DepartmentPerformanceModule"
            :DepartmentPerformanceList="DepartmentPerformanceList"
            :usedDepartmentPerformanceList="usedDepartmentPerformanceList"
            ref="departmentperformance"
          ></DepartmentPerformance>
        </el-tab-pane>
        <el-tab-pane
          v-if="permission(['PERFORMANCE_RULES_VIEW'])"
          label="浮动工资"
          name="浮动工资"
        >
          <FloatingWage
            :source="source"
            :rulesType="rulesType"
            :FloatingWageModule="FloatingWageModule"
            :FloatingWageList="FloatingWageList"
            :usedFloatingWageList="usedFloatingWageList"
            ref="floatingwage"
          ></FloatingWage>
        </el-tab-pane>
      </el-tabs>

      <div
        class="fullScreenOperation"
        v-if="permission(['PERFORMANCE_RULES_EDIT'])"
      >
        <el-button type="primary" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
    ExpenseRules: () => import("./ExpenseRules.vue"),
    BusinessPerformance: () => import("./BusinessPerformance.vue"),
    DepartmentPerformance: () => import("./DepartmentPerformance.vue"),
    FloatingWage: () => import("./FloatingWage.vue"),
  },
  props: {},
  data() {
    return {
      activeName: "可支配费用规则",
      BusinessPerformanceList: [],
      DepartmentPerformanceList: [],
      ExpenseRulesList: [],
      FloatingWageList: [],
      usedBusinessPerformanceList: [],
      usedDepartmentPerformanceList: [],
      usedExpenseRulesList: [],
      usedFloatingWageList: [],
      rulesType: [],
      oldData: "可支配费用规则",
      ExpenseRulesModule: { module: "" },
      FloatingWageModule: { module: "" },
      DepartmentPerformanceModule: { module: "" },
      BusinessPerformanceModule: { module: "" },
      loading: false,
      source: 0,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  created() {
    this.$api.dict
      .listSysDictData("GENERAL_RULE_MODULE", true)
      .then((res) => {
        this.rulesType = res.data;
        res.data.forEach((v) => {
          if (v.dictName == "可支配费用规则") {
            this.ExpenseRulesModule.module = v.dictVal;
            this.$api.performanceRules
              .generalRuleList({
                module: v.dictVal,
              })
              .then((res) => {
                this.ExpenseRulesList = res.data;
                this.usedExpenseRulesList = res.data.deepClone();
              })
              .catch((err) => {
                console.log(err);
              });
            // this.$api.performanceRules
            //   .generalRuleLogList({
            //     module: v.dictVal,
            //   })
            //   .then((res) => {
            //     console.log(res.data, "res");
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
          } else if (v.dictName == "商务绩效规则") {
            this.BusinessPerformanceModule.module = v.dictVal;
            this.$api.performanceRules
              .generalRuleList({
                module: v.dictVal,
              })
              .then((res) => {
                this.BusinessPerformanceList = res.data;
                this.usedBusinessPerformanceList = res.data.deepClone();
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (v.dictName == "部门绩效规则") {
            this.DepartmentPerformanceModule.module = v.dictVal;
            this.$api.performanceRules
              .generalRuleList({
                module: v.dictVal,
              })
              .then((res) => {
                this.DepartmentPerformanceList = res.data;
                this.usedDepartmentPerformanceList = res.data.deepClone();
              })
              .catch((err) => {
                console.log(err);
              });
          } else if (v.dictName == "浮动工资") {
            this.FloatingWageModule.module = v.dictVal;
            this.$api.performanceRules
              .generalRuleList({
                module: v.dictVal,
              })
              .then((res) => {
                this.FloatingWageList = res.data;
                this.usedFloatingWageList = res.data.deepClone();
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    submit() {
      this.$confirm(`是否要修改${this.activeName}页面`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          if (this.activeName == "可支配费用规则") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.$api.performanceRules
                  .generalRuleEdit(this.ExpenseRulesList)
                  .then((res) => {
                    this.$api.performanceRules
                      .generalRuleList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        this.ExpenseRulesList = res.data;
                        this.usedExpenseRulesList = res.data.deepClone();
                        this.loading = false;
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });
            return;
          }

          if (this.activeName == "商务绩效规则") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.$api.performanceRules
                  .generalRuleEdit(this.BusinessPerformanceList)
                  .then((res) => {
                    this.$api.performanceRules
                      .generalRuleList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        this.BusinessPerformanceList = res.data;
                        this.usedBusinessPerformanceList = res.data.deepClone();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });
            return;
          }

          if (this.activeName == "部门绩效规则") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.$api.performanceRules
                  .generalRuleEdit(this.DepartmentPerformanceList)
                  .then((res) => {
                    this.$api.performanceRules
                      .generalRuleList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        this.DepartmentPerformanceList = res.data;
                        this.usedDepartmentPerformanceList = res.data.deepClone();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });
            return;
          }

          if (this.activeName == "浮动工资") {
            this.rulesType.forEach((v) => {
              if (v.dictName == this.activeName) {
                this.$api.performanceRules
                  .generalRuleEdit(this.FloatingWageList)
                  .then((res) => {
                    this.$api.performanceRules
                      .generalRuleList({
                        module: v.dictVal,
                      })
                      .then((res) => {
                        this.FloatingWageList = res.data;
                        this.usedFloatingWageList = res.data.deepClone();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.loading = false;
                      });
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                    this.loading = false;
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              }
            });

            return;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "您已取消修改",
          });
          this.loading = false;
        });
    },
    handleClick() {
      let ExpenseRulesisShow = false;
      let BusinessPerformanceisShow = false;
      let DepartmentPerformanceisShow = false;
      let FloatingWageisShow = false;
      if (this.oldData == "可支配费用规则") {
        try {
          this.usedExpenseRulesList.forEach((v) => {
            this.ExpenseRulesList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (
                    a1.ruleDataList.length > 0 &&
                    a2.ruleDataList.length > 0
                  ) {
                    a1.ruleDataList.forEach((b1) => {
                      a2.ruleDataList.forEach((b2) => {
                        if (
                          b1.id == b2.id &&
                          b1.ruleType == "PER_CENT" &&
                          b2.ruleType == "PER_CENT"
                        ) {
                          if (Number(b1.code) != Number(b2.code)) {
                            ExpenseRulesisShow = true;
                            throw new Error("EndIterative");
                          }
                        }
                        if (
                          b1.ruleGroupList.length > 0 &&
                          b2.ruleGroupList.length > 0
                        ) {
                          b1.ruleGroupList.forEach((c1) => {
                            b2.ruleGroupList.forEach((c2) => {
                              if (
                                c1.id == c2.id &&
                                c1.ruleType == "VALUE" &&
                                c2.ruleType == "VALUE"
                              ) {
                                if (Number(c1.code) != Number(c2.code)) {
                                  ExpenseRulesisShow = true;
                                  throw new Error("EndIterative");
                                }
                              }
                            });
                          });
                        }
                      });
                    });
                  }
                });
              });
            });
          });
        } catch (e) {}
        if (ExpenseRulesisShow) {
          this.$confirm("可支配费用规则已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "可支配费用规则";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }

      if (this.oldData == "商务绩效规则") {
        try {
          this.usedBusinessPerformanceList.forEach((v) => {
            this.BusinessPerformanceList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (
                    a1.ruleDataList.length > 0 &&
                    a2.ruleDataList.length > 0
                  ) {
                    a1.ruleDataList.forEach((b1) => {
                      a2.ruleDataList.forEach((b2) => {
                        if (
                          b1.id == b2.id &&
                          b1.ruleType == "PER_CENT" &&
                          b2.ruleType == "PER_CENT"
                        ) {
                          if (Number(b1.code) != Number(b2.code)) {
                            BusinessPerformanceisShow = true;
                            throw new Error("EndIterative");
                          }
                        }
                        if (
                          b1.ruleGroupList.length > 0 &&
                          b2.ruleGroupList.length > 0
                        ) {
                          b1.ruleGroupList.forEach((c1) => {
                            b2.ruleGroupList.forEach((c2) => {
                              if (
                                c1.id == c2.id &&
                                c1.ruleType == "VALUE" &&
                                c2.ruleType == "VALUE"
                              ) {
                                if (Number(c1.code) != Number(c2.code)) {
                                  BusinessPerformanceisShow = true;
                                  throw new Error("EndIterative");
                                }
                              }
                            });
                          });
                        }
                      });
                    });
                  }
                });
              });
            });
          });
        } catch (e) {}

        if (BusinessPerformanceisShow) {
          this.$confirm("商务绩效规则已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "商务绩效规则";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }

      if (this.oldData == "部门绩效规则") {
        try {
          this.DepartmentPerformanceList.forEach((v) => {
            this.usedDepartmentPerformanceList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (
                    a1.ruleDataList.length > 0 &&
                    a2.ruleDataList.length > 0
                  ) {
                    a1.ruleDataList.forEach((b1) => {
                      a2.ruleDataList.forEach((b2) => {
                        if (
                          b1.id == b2.id &&
                          b1.ruleType == "PER_CENT" &&
                          b2.ruleType == "PER_CENT"
                        ) {
                          if (Number(b1.code) != Number(b2.code)) {
                            DepartmentPerformanceisShow = true;
                            throw new Error("EndIterative");
                          }
                        }
                        if (
                          b1.ruleGroupList.length > 0 &&
                          b2.ruleGroupList.length > 0
                        ) {
                          b1.ruleGroupList.forEach((c1) => {
                            b2.ruleGroupList.forEach((c2) => {
                              if (
                                c1.id == c2.id &&
                                c1.ruleType == "VALUE" &&
                                c2.ruleType == "VALUE"
                              ) {
                                if (Number(c1.code) != Number(c2.code)) {
                                  DepartmentPerformanceisShow = true;
                                  throw new Error("EndIterative");
                                }
                              }
                            });
                          });
                        }
                      });
                    });
                  }
                });
              });
            });
          });
        } catch (e) {}

        if (DepartmentPerformanceisShow) {
          this.$confirm("部门绩效规则已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "部门绩效规则";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }

      if (this.oldData == "浮动工资") {
        try {
          this.FloatingWageList.forEach((v) => {
            this.usedFloatingWageList.forEach((k) => {
              v.ruleList.forEach((a1) => {
                k.ruleList.forEach((a2) => {
                  if (
                    a1.ruleDataList.length > 0 &&
                    a2.ruleDataList.length > 0
                  ) {
                    a1.ruleDataList.forEach((b1) => {
                      a2.ruleDataList.forEach((b2) => {
                        if (
                          b1.id == b2.id &&
                          b1.ruleType == "PER_CENT" &&
                          b2.ruleType == "PER_CENT"
                        ) {
                          if (Number(b1.code) != Number(b2.code)) {
                            FloatingWageisShow = true;
                            throw new Error("EndIterative");
                          }
                        }
                        if (
                          b1.ruleGroupList.length > 0 &&
                          b2.ruleGroupList.length > 0
                        ) {
                          b1.ruleGroupList.forEach((c1) => {
                            b2.ruleGroupList.forEach((c2) => {
                              if (
                                c1.id == c2.id &&
                                c1.ruleType == "VALUE" &&
                                c2.ruleType == "VALUE"
                              ) {
                                if (Number(c1.code) != Number(c2.code)) {
                                  FloatingWageisShow = true;
                                  throw new Error("EndIterative");
                                }
                              }
                            });
                          });
                        }
                      });
                    });
                  }
                });
              });
            });
          });
        } catch (e) {}

        if (FloatingWageisShow) {
          this.$confirm("浮动工资规则已修改末保存, 是否继续切换?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //  确定
            })
            .catch(() => {
              // 取消
              this.activeName = "浮动工资";
              this.oldData = this.activeName;
            });
        }
        this.oldData = this.activeName;
        return;
      }
    },
    async getData(arr, newArr, type) {
      const res = await this.$api.performanceRules.generalRuleList({
        module: type,
      });
      arr = res.data.deepClone();
      newArr = res.data;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
.fullScreen {
  .cost {
    padding: 20px 0;
    /deep/.el-timeline-item__timestamp {
      font-size: 16px;
      font-weight: bold;
      color: #303133;
    }
  }
  .danwei {
    position: absolute;
    right: 2%;
  }
  /deep/#checkOpinion {
    .el-form-item__label {
      font-size: 16px !important;
      color: red !important;
    }
  }
  .Taxes {
    display: inline-block;
    width: 100%;
    height: 40px;
    padding-left: 12px;
    border: 1px solid #ccc;
    border-radius: 7px;
  }
}
</style>
